import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {

  constructor(private router: Router, private authService: AuthService) { }

  ngOnInit() {
  // if (this.authService.isLoggedOut)
  const a = this.authService.isLoggedOut;
  if (localStorage.getItem('ACCESS_TOKEN') == null) {
   this.authService.logout();
  }
  this.router.navigate(['/login']);
    }

}
