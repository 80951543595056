import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders, HttpErrorResponse } from '@angular/common/http';  
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  public isLoggedIn(){
    return localStorage.getItem('ACCESS_TOKEN') !== null;
  }

  public logout(){
    localStorage.removeItem('ACCESS_TOKEN');
    sessionStorage.removeItem('ACCESS_TOKEN');    
    sessionStorage.clear();
    localStorage.clear();
    }

    public isLoggedOut(){
      return localStorage.getItem('ACCESS_TOKEN') == null
    }
}
