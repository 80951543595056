import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './view/home/home.component';
import { AboutusComponent } from './view/aboutus/aboutus.component';
import { CareerComponent } from './view/career/career.component';
import { NewsComponent } from './view/news/news.component';
import { ContactusComponent } from './view/contactus/contactus.component';
import { ProjectsComponent } from './view/projects/projects.component';
import { AdmonFinancialComponent } from './view/admon-financial/admon-financial.component';
import { AdmonBursaryComponent } from './view/admon-bursary/admon-bursary.component';
import { AdmonAuditComponent } from './view/admon-audit/admon-audit.component';
import { AdmonPayComponent } from './view/admon-pay/admon-pay.component';
import { AdmonHRMComponent } from './view/admon-hrm/admon-hrm.component';
import { LoginComponent } from './view/login/login.component';
import { ProductsComponent } from './view/products/products.component';

const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch : 'full'},
  {path: 'home', component : HomeComponent},
  {path: 'aboutus', component : AboutusComponent},
  {path: 'career', component : CareerComponent},
  {path: 'news', component : NewsComponent},
  {path: 'contactus', component : ContactusComponent},
  {path: 'projects', component : ProjectsComponent},
  {path: 'AdmonFinancial', component : AdmonFinancialComponent},
  {path: 'AdmonBursary', component : AdmonBursaryComponent},
  {path: 'AdmonAudit', component : AdmonAuditComponent},
  {path: 'AdmonPay', component : AdmonPayComponent},
  {path: 'AdmonHRM', component : AdmonHRMComponent},
  {path: 'login', component : LoginComponent},
  {path: 'products', component : ProductsComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [HomeComponent, AboutusComponent, CareerComponent, NewsComponent,
  ContactusComponent, ProjectsComponent, AdmonFinancialComponent, AdmonBursaryComponent, AdmonAuditComponent,
  AdmonPayComponent, AdmonHRMComponent, LoginComponent, ProductsComponent
];
