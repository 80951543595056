import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpErrorResponse, HttpParams  } from '@angular/common/http';
import { EnvironmentUrlService } from './environment-url.service';
@Injectable({
  providedIn: 'root'
})
export class RepositoryService {

  constructor(private http: HttpClient, private envUrl: EnvironmentUrlService) { }

  public getData(route: string) {
     return this.http.get(this.createCompleteRoute(route, this.envUrl.urlAddress));
   }
  
   public create(route: string, body) {
     return this.http.post(this.createCompleteRoute(route, this.envUrl.urlAddress), body, this.generateHeaders());
   }
  
   public login(route: string, body) {
     return this.http.post(this.createCompleteRoute(route, this.envUrl.urlAddress), body, this.generateHeaders());
   }
   public update(route: string, body){
     return this.http.put(this.createCompleteRoute(route, this.envUrl.urlAddress), body, this.generateHeaders());
   }
  
   public delete(route: string){
     return this.http.delete(this.createCompleteRoute(route, this.envUrl.urlAddress));
   }
  
   private createCompleteRoute(route: string, envAddress: string) {
     return `${envAddress}/${route}`;
   }
  
   private generateHeaders() {
     return {
       headers: new HttpHeaders({'Content-Type': 'application/json'})
     }

     
    }
}
