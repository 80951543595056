import { Component, OnInit } from '@angular/core';
import { RepositoryService } from  '../../shared/services/repository.service'; 
import { HttpClient, HttpHeaders,HttpErrorResponse, HttpParams  } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators , NgForm, NgModel} from  '@angular/forms';
import { Router } from  '@angular/router';
import { AuthService } from  '../../shared/services/auth.service'; 

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  isLoginError : boolean = false;
  errorMessage:string; 
  model : any={}; 
  Responses;
  public result: any;

  constructor(private router: Router, private formBuilder: FormBuilder, private repo: RepositoryService,private authService: AuthService) { }
  loginForm: FormGroup;
  isSubmitted  =  false;

  ngOnInit() {
    this.loginForm  =  this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.authService.logout();
  }
  get formControls() { return this.loginForm.controls; }

  getLogin(){  
    if (this.loginForm.invalid) {
      return;
      }  
    debugger; 
    this.model =this.loginForm.value;     
      this.repo.login('api/logins/Login',this.model)
      .subscribe((res:any) => {
        this.result = res;
        if(this.result.status=="Success")    
         {   
          localStorage.setItem('ACCESS_TOKEN', "access_token");
          sessionStorage.setItem('ACCESS_TOKEN', "access_token");
           this.router.navigate(['/projects']); 
         }    
         else{    
           this.errorMessage = this.result.message; 
           this.isLoginError = true;
           this.authService.logout
         }    
      },
      (error) => {
        this.isLoginError = true;
        this.authService.logout
        if (error.status == 400)      
        this.errorMessage = 'An error occurred!!!. Authentication failed'; 
        else
        this.errorMessage = 'An error occurred!!!. Authentication failed';    
       
       console.error('An error occurred', error);
      })

  }; 


}
